<template>
    <div class="form-input">
        <label v-if="label" :for="id ?? name" class="input-label" v-html="label"> </label>
        <div class="form-element-wrap">
            <input
                :id="id ?? name"
                class="form-element"
                :class="{ 'has-error': errorMessage }"
                :type="type ?? 'text'"
                :name="name"
                :placeholder="placeholder"
                :value="modelValue"
                @input="(event) => emit('update:modelValue', (event.target as HTMLInputElement).value)"
                @focus="() => emit('focus')"
                @blur="() => emit('blur')"
            />
            <div v-if="errorMessage" class="error-message">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps<{
    id?: string;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
    modelValue?: string;
    errorMessage?: string | Ref<string>;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: any): void;
    (e: 'focus'): void;
    (e: 'blur'): void;
}>();
</script>
