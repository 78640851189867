<template>
    <div>
        <UiFormInput
            :id="id"
            :name="name"
            :label="label"
            :type="type"
            :placeholder="placeholder"
            :model-value="modelValue"
            :error-message="errorMessage"
            @update:model-value="emit('update:modelValue', $event)"
            @focus="() => emit('focus')"
            @blur="() => emit('blur')"
        />
    </div>
</template>

<script setup lang="ts">
defineProps<{
    id?: string;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
    modelValue?: string;
    errorMessage?: string | Ref<string>;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: any): void;
    (e: 'focus'): void;
    (e: 'blur'): void;
}>();
</script>

<style lang="postcss" scoped>
:deep(.form-input) {
    @apply py-1 flex flex-col text-text-primary-asphalt;

    .input-label {
        @apply mb-1 text-text-primary-asphalt text-sm block;
    }
    .form-element {
        @apply w-full block px-4 py-3 bg-white text-base text-text-primary-asphalt rounded-lg border-solid border-neutral-mid-grey border transition-colors h-12;
        &:focus {
            @apply border-brand-primary-500 outline-brand-primary-500 outline outline-2;
        }
        &.has-error {
            @apply border-status-error-red-100;
            &:focus {
                @apply border-status-error-red-100 outline-status-error-red-100;
            }
        }
    }
    .error-message {
        @apply text-xs text-status-error-red-100;
    }
}
</style>
